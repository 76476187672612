import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import NewServiceOne from "../Components/FromSidebar/Tutor/Service/NewServiceOne";
import NewServiceTwo from "../Components/FromSidebar/Tutor/Service/NewServiceTwo";
import NewServiceThree from "../Components/FromSidebar/Tutor/Service/NewServiceThree";
import { API_BASE_URL } from "../apiPaths";

const NewService = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState([]);
  const [isEnoughServices, setIsEnoughServices] = useState(true);
  const newServiceData = new FormData();

  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const tempId = searchParams.get("temp-id");
  useEffect(() => {
    if (id) {
      async function fetchData() {
        try {
          const response = await fetch(
            `${API_BASE_URL}/service-builder/get-by-id/${id}`,
            {
              headers: {
                "x-access-token": currentUser.token,
              },
            }
          );
          const service = await response.json();
          service.data.service.isService = true;
          setFormData(service?.data.service);
          if(service?.data.totalServicesCount < 2) setIsEnoughServices(false)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }

      fetchData();
    }

    if (tempId) {
      async function fetchData() {
        try {
          const response = await fetch(
            `${API_BASE_URL}/service-builder/template/get/${tempId}`,
            {
              headers: {
                "x-access-token": currentUser.token,
              },
            }
          );
          const template = await response.json();
          template.data.isTemplate = true
          
          setFormData(template?.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }

      fetchData();
    }
  }, [currentUser.token, id, location]);

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  if (!id && !tempId && formData && formData.questionsAnswers) {
    newServiceData.append("session_title", formData?.session_title);
    newServiceData.append("description", formData?.description);
    newServiceData.append("rate_price", formData?.rate_price);
    newServiceData.append("rate_type", formData?.rate_type);
    newServiceData.append("image", formData?.cover_image[0]);
    newServiceData.append(
      "questionsAnswers",
      JSON.stringify(formData.questionsAnswers)
    );
  }

  if ((id || tempId) && formData && formData.questionsAnswers) {
    newServiceData.append("session_title", formData?.session_title);
    newServiceData.append("description", formData?.description);
    newServiceData.append("rate_price", formData?.rate_price ? formData?.rate_price : "0");
    newServiceData.append("rate_type", formData?.rate_type);
    newServiceData.append("image", formData?.image);
    newServiceData.append("id", formData?._id);
  }

  const steps = [
    {
      content: (
        <NewServiceOne
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          setFormData={setFormData}
          formData={formData}
          isEnoughServices = {isEnoughServices}
        />
      ),
    },
    {
      content: (
        <NewServiceTwo
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          setFormData={setFormData}
          formData={formData}
          serviceId={id}
          isEnoughServices = {isEnoughServices}
        />
      ),
    },
    {
      content: (
        <NewServiceThree
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          newServiceData={newServiceData}
          formData={formData}
          serviceId={id}
          isEnoughServices = {isEnoughServices}
        />
      ),
    },
  ];

  return <Box>{steps[activeStep].content}</Box>;
};

export default NewService;
