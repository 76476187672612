import React, { useState, useEffect } from "react";
import BookSessionFormHeaderOne from "../../../Headers/BookSessionFormHeaderOne";
import ServiceListing from "./TutorServiceListing";
import {
  Box,
  Button,
  ModalHeader,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { useParams, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../../apiPaths";
import MyCalendar from "../../../Calender/StdCalendar/Calender";

export default function BookSessionFormOne() {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          width: "30px", // Increase the width
          height: "30px",
          position: "absolute",
          borderRadius: "60%",
          top: "-7%",
          left: "95%",
        }}
        onClick={onClick}></div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,

          borderRadius: "50%",
          width: "30px", // Increase the width
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "-7%",
          left: "91%",
        }}
        onClick={onClick}
      />
    );
  }

  const { tutorId } = useParams();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [serviceData, setServiceData] = useState(null);
  const [open, setOpen] = useState(false);
  const { isOpen, onClose } = useDisclosure();
  const showArrows = serviceData && serviceData.length > 5;
  const settings = {
    className: "center",

    centerPadding: "0px",
    slidesToShow: 4,
    speed: 500,
    nextArrow: showArrows ? <SampleNextArrow /> : null,
    prevArrow: showArrows ? <SamplePrevArrow /> : null,
    responsive: [
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,

          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          nextArrow: false,
          prevArrow: false,

          slidesToShow: 2,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: "40px",
        },
      },
    ],
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API_BASE_URL}/service-builder/choose-service/${tutorId}`,
          {
            headers: {
              "x-access-token": currentUser.token,
            },
          }
        );
        const sessions = await response.json();
        if (sessions) {
          setOpen(true);
        }
        setServiceData(sessions.data[0].data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <Box>
      <MyCalendar />
      <Modal
        blockScrollOnMount={false}
        isOpen={open}
        isCentered
        display={"flex"}
        width={"117vw"}
        justifyContent={"center"}
        alignItems={"center"}
        onClose={onClose}
        size={{
          base: "xl",
          sm: "lg",
          md: "xl",
          lg: "4xl",
          xl: "4xl",
          "2xl": "6xl",
        }}>
        <ModalContent bg="#fff" height={{ base: "auto", sm: "auto" }}>
          <ModalHeader>
            <BookSessionFormHeaderOne Close={onClose} />
          </ModalHeader>

          <Slider {...settings}>
            {serviceData?.map((service) =>
              service?.name !== "Any" || service?.name !== "One to One Session" ? (
                <ServiceListing key={service._id} data={service} />
              ) : null
            )}
          </Slider>
        </ModalContent>
      </Modal>
    </Box>
  );
}
