import { 
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Box, 
  Flex, 
  Image, 
  Button, 
  Text, 
  Link 
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { API_BASE_URL, SERVICE_IMAGE_URL, USER_IMAGE_URL } from "../../apiPaths";

const RequestActionModal = ({
  isOpen,
  onClose,
  requestID,
  senderName,
  serviceName,
  date,
  rateType,
  rate,
  serviceImage,
  senderImage
}) => {
  const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);
  const [request, setRequest] = useState({});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isConfirm = params.get('isconfirm') === 'true';
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  const onCancel = () => {
    setIsConfirmBtnClicked(false);
    onClose();
  }

  useEffect(() => {
    // console.log("requestID",   requestID);
    async function fetchRequest() {
      const response = await fetch(`${API_BASE_URL}/session-request/get-by-id/${requestID}`, {
        headers: {
          'x-access-token': currentUser.token 
        }
      });
      const request = await response.json();
      console.log(request.data);
      
      setRequest(request.data)
      
    }

    fetchRequest();
    
    if(isConfirm) {
      setIsConfirmBtnClicked(true);      
    }
  }, [isConfirm]);

  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent
        bg="white"
        color="black"
        position="relative"
        py={{ base: "10px", xl: "40px" }}
      >
        <ModalCloseButton position="absolute" top="10px" left="10px" />
        <form>
          <ModalBody px={{ base: "20px", xl: "40px" }} pb={6}>
            <Box textAlign="center" width="" margin="auto">
              <Text as="h1" fontSize="3xl" fontWeight="bold">LOGO</Text>
              <Text>{request?.user?.firstName} {request?.user?.lastName} would like a <b>{request?.service?.name}</b> session with you</Text>
              <Text>{new Date(request?.start).toLocaleString('en-US', { 
                  year: 'numeric', 
                  month: 'long', 
                  day: 'numeric', 
                  hour: 'numeric', 
                  minute: 'numeric', 
                  hour12: true 
                })}</Text>
              <Text>{request?.sessionLocation}</Text>
              <Text>
                {rateType} {rate}
              </Text>

              <Flex justifyContent="space-between" mb="30px" mt="20px">
                <Box textAlign="left" width="50%">
                  <Image
                    src={`${SERVICE_IMAGE_URL}/${request?.service?.image}`}
                    alt="Service Image"
                    width="200px"
                    height="200px"
                    crossOrigin="annonymous"
                  />
                  <Text fontSize="lg">{request?.service?.name }</Text>
                </Box>
                <Box textAlign="right">
                  <Image
                    src={`${USER_IMAGE_URL}/${request?.user?.profilePic}`}
                    alt="Service Image"
                    width="200px"
                    height="200px"
                    style={{ borderRadius: "50%" }} // You can use style or CSS classes for border-radius
                    crossOrigin="annonymous"
                  />
                  <Text fontSize="lg">{request?.user?.firstName} {request?.user?.lastName}</Text>
                </Box>
              </Flex>

              <Link href="#">
                <Button
                  bg="black"
                  color="white"
                  width="100%"
                  borderRadius="20px"
                  py="20px"
                  fontWeight="bold"
                  mb="10px"
                  _hover={{ bg: "gray.700" }}
                >
                  Confirm
                </Button>
              </Link>

              <Link href="#">
                <Button
                  bg="transparent"
                  color="black"
                  border="1px solid black"
                  width="100%"
                  borderRadius="20px"
                  py="8px"
                  fontWeight="bold"
                  mb="10px"
                  _hover={{ bg: "gray.200" }}
                >
                  Modify
                </Button>
              </Link>

              <Link href="#">
                <Button
                  bg="#E1526C"
                  color="white"
                  width="100%"
                  borderRadius="20px"
                  py="10px"
                  fontWeight="bold"
                  mb="10px"
                  _hover={{ bg: "#c13e59" }}
                >
                  Reject
                </Button>
              </Link>
            </Box>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
     {/* Login Modal */}
     <Modal
        isOpen={isConfirmBtnClicked}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg={"#fff"} color="#000">
          <ModalBody>
            <Text mb={4} mt={4} align="center" >
              Would you like to "CHOICE" this session?
            </Text>
            <Flex direction="row" align="center" justify="space-between" px="70px" mb="3">
                <Button
                  color="black"
                  fontSize="15px"
                  fontWeight="600">
                  Yes
                </Button>
                <Button
                  onClick={() => onCancel()}
                  color="red"
                  fontSize="15px"
                  fontWeight="600">
                  CANCEL
                </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
   </>
  );
};

export default RequestActionModal;
