import React from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { SERVICE_IMAGE_URL } from "../../../../apiPaths";
export default function ServiceListing(data) {
  const session = data?.data;
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  return (
    <Box>
      <style>
        {`

        .slick-track {
          display: flex;
        }
        .slick-slide {
            padding: 0 5px; 
            width: 230px !important;
          }
        
    
       
          

  

         
         

            
          }          
        `}
      </style>
      <Flex
        flexDirection={"column"}
        rounded="8px"
        p={{ base: 5, lg: 5 }}
        boxShadow={"md"}
        m={{ base: 2, lg: 1 }}>
        <Box color="white" display={"flex"} justifyContent={"center"} mt={2}>
          {session?.image && (
            <Image
              objectFit={{ base: "cover", md: "cover" }}
              src={SERVICE_IMAGE_URL + "" + session?.image}
              alt="Session Image"
              height={200}
              width={230}
              backgroundSize="cover"
              crossOrigin="anonymous"
            />
          )}
        </Box>
        <Flex flexDirection={"column"} p={{ base: 2 }}>
          <Flex flexDirection={"column"}>
            <Text
              fontSize={{ base: "12px", xl: "14px", "2xl": "16px" }}
              fontWeight="600"
              color={"#000"}
              mt={2}>
              {session?.name ? session?.name : "One to One Session"}
            </Text>

            <Box
              fontSize={{ base: "10px", xl: "12px", "2xl": "14px" }}
              fontWeight="600"
              color={"#616161"}
              my={2}>
              £{session?.rate}.00 {session?.rateType}
            </Box>
          </Flex>
          <Flex>
            <Button
              as={Link}
              to={`/book-session/${session?._id}?tid=${session?.user?._id}`}
              bg="black"
              _hover={{
                bg: "white",
                color: "black",
                border: "1px solid black",
              }}
              border="1px solid #f2f2f2"
              color="white"
              rounded="40px"
              py={{ base: "8px", lg: "12px" }}
              px={{ base: "8px", lg: "40px" }}
              w={{ base: "100%", "2xl": "auto" }}
              fontSize={{ base: "12px", xl: "14px", "2xl": "14px" }}
              fontWeight="600">
              Select
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
